










import Component from 'vue-class-component';
import Vue from 'vue';
import RsDashboardHeader from '@/modules/dashboard/components/main/header.vue';
import RsDashboardJobStatus from '@/modules/dashboard/components/global/job-status/job-status.vue';
@Component({
  components: {RsDashboardJobStatus, RsDashboardHeader}
})
export default class Dashboard extends Vue {}
