








































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {DashboardRouter} from '@/modules/dashboard/router';
import {ProfileRouter} from '@/modules/dashboard/modules/profile/router';
import {AssesmentsRouter} from '@/modules/dashboard/modules/assesments/router';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {Watch} from 'vue-property-decorator';
import {ReferralRouter} from '@/modules/dashboard/modules/referral/router';
import RsIcon from '@/shared/components/icon/icon.vue';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {OnboardingRouter} from '@/modules/onboarding/router';
import RsDashboardJobStatusHeader from '@/modules/dashboard/components/global/job-status/job-status-header.vue';
import {Icons} from '@/shared/components/icon/icons';
import {ApplicationsRouter} from '@/modules/dashboard/modules/applications/router';

const body = document.querySelector('body');

@Component({
  name: 'rs-dashboard-header',
  components: {RsDashboardJobStatusHeader, RsIcon}
})
export default class DashboardHeader extends Vue {
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;
  @Action(OnboardingActions.LOGOUT, {namespace: OnboardingModule.namespace}) logoutUser: () => void;

  DashboardRouter = DashboardRouter;
  ProfileRouter = ProfileRouter;
  AssesmentsRouter = AssesmentsRouter;
  JobsRouter = JobsRouter;
  ReferralRouter = ReferralRouter;
  ApplicationsRouter = ApplicationsRouter;
  Icons = Icons;
  env = process.env;

  open = false;
  settings = false;

  scroll? = 0;
  previousScroll? = 0;

  @Watch('$route')
  routeChange() {
    this.open = false;
    this.settings = false;
  }

  logout() {
    this.settings = false;
    this.logoutUser();
    this.$router.push({name: OnboardingRouter.LOGIN});
  }

  handleScroll() {
    const scroll = body?.scrollTop;
    this.previousScroll = this.scroll;
    this.scroll = scroll;

    if(scroll && scroll > 0) {
      this.settings = false;
    }
  }

  created() {
    body?.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    body?.removeEventListener('scroll', this.handleScroll);
  }
}
