








































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsModal from '@/shared/components/modal/modal.vue';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import RsDashboardJobStatusIcon from '@/modules/dashboard/components/global/job-status/job-status-icon.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {AVAILABILITY} from '@/shared/constants/AVAILABILITY';
import {Watch} from 'vue-property-decorator';
import RsButton from '@/shared/components/buttons/button.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-dashboard-job-status',
  components: {RsButton, RsIcon, RsDashboardJobStatusIcon, RsModal},
})
export default class DashboardJobStatus extends Vue {
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;

  AVAILABILITY = AVAILABILITY;
  Icons = Icons;

  isOpened = false;
  selected = AVAILABILITY.ACTIVELY_LOOKING;

  sending = false;

  @Watch('user')
  onUserChange(user: IApiTalent) {
    this.selected = user.properties.AVAILABILITY || AVAILABILITY.ACTIVELY_LOOKING;
    this.selected = user.properties.AVAILABILITY || AVAILABILITY.ACTIVELY_LOOKING;
  }

  open() {
    this.isOpened = true;
    this.selected = this.user.properties.AVAILABILITY || AVAILABILITY.ACTIVELY_LOOKING;
  }

  submit() {
    this.sending = true;
    const data: IApiTalentUpdateRequest = {
      properties: {
        AVAILABILITY: this.selected,
      },
    }
    API_SERVICE.auth.updateUser(data)
      .then((res) => {
        this.setUser(res);
        this.isOpened = false;
        // @ts-ignore
        this.$rs.toast({
          icon: Icons.CHECKMARK_CHECKED,
          title: 'Changes saved',
          body: 'Your job search status has been updated'
        })
      })
      .finally(() => {
        this.sending = false;
      })
  }

  created() {
    // @ts-ignore
    this.$rs.jobStatus = this;
  }
}
